import React from "react";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import PageWrapper from "components/PageWrapper";
import RichText from "components/RichText";
import log from "utils/log";
import * as siteTheme from "theme";
import * as heroClasses from "./hero.module.css";

export const query = graphql`
  query {
    descriptionImage: contentfulAsset(
      contentful_id: { eq: "4MO4JDc5UBW9bUV9oR5DaR" }
    ) {
      id
      fixed {
        src
      }
    }
    heroImage: contentfulAsset(
      contentful_id: { eq: "5IwXaSmiTzxGMlebP47sO9" }
    ) {
      id
      fluid {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    nana: allContentfulText(
      filter: { contentful_id: { eq: "5MGkwJg31JpLiOnLNDmGCY" } }
    ) {
      nodes {
        id
        text {
          raw
        }
      }
    }
    images: contentfulGallery(
      id: { eq: "9316d805-ca94-5ae3-ae02-9a0a66a3273a" }
    ) {
      id
      galleryItems {
        id
        fluid(maxWidth: 2048) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;

function present(data) {
  const seo = {
    title: "Home",
    image: data?.descriptionImage?.fixed?.src,
    description:
      "Nana's Haven in St. Petersburg, FL offers support and services for neglected and abused animals. Learn more about us today.",
  };

  const presentation = {
    seo,
    nana: data?.nana?.nodes[0]?.text,
    heroImage: data?.heroImage?.fluid,
    images: data?.images?.galleryItems,
  };

  log("[PAGE] presentation", presentation);

  return presentation;
}

export default function Page({ data }) {
  const { images, nana, heroImage, seo } = present(data);

  return (
    <PageWrapper seo={seo}>
      {/* Hero ---> */}
      <div className="container mx-auto px-8 md:px-16">
        <div className={heroClasses.heroLayout}>
          <div className={heroClasses.heroText}>
            <div className={heroClasses.heroTextLayout}>
              <h1 className={heroClasses.heroTitle}>Nana The Warrior</h1>
            </div>
          </div>
          <div className={heroClasses.heroImage}>
            <GatsbyImage fluid={heroImage} />
          </div>
        </div>
      </div>
      {/* <---- Hero */}

      <div className="py-32">
        <div className="container mx-auto px-8 md:px-16">
          <div className="md:grid md:gap-8 md:grid-cols-12 mb-16">
            <div
              className="md:col-start-3 md:col-span-8"
              style={{ columnCount: 2, columnGap: "1.5rem" }}
            >
              <RichText
                json={nana}
                theme={{
                  title: {
                    color: siteTheme.heading.primary,
                    fontFamily: siteTheme.font.primary,
                  },
                }}
              />
            </div>
          </div>
          <div className="md:grid md:gap-8 md:grid-cols-12">
            <div className="md:col-span-10 md:col-start-2 md:grid md:grid-cols-9 md:gap-8">
              {images.map(({ id, fluid }) => (
                <div key={id} className="md:col-span-3">
                  <GatsbyImage fluid={fluid} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
